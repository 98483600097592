.payfast-button {
  color: var(--danger) !important;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  font-size: 0.8rem;
  line-height: 1.1375rem;
}

.payfast-button:hover {
  color: #79da45 !important;
}