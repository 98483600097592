.custom-color-picker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  outline: none;
}

/* Fix for Chrome, Edge, and Safari */
.custom-color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
  margin: 0;
  border: none;
}

/* Remove border & apply border-radius to color swatch */
.custom-color-picker::-webkit-color-swatch {
  border-radius: 8px;
  border: none;
}

/* Firefox Fix */
.custom-color-picker::-moz-color-swatch {
  border-radius: 8px;
  border: none;
}