.fc {
  color: var(--font) !important;
  background-color: var(--grey7) !important;
}

.fc th {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.fc th,
.fc td,
.fc hr,
.fc thead,
.fc tbody,
.fc-row {
  border: none !important;
}

.fc-day-disabled {
  background-color: var(--grey5) !important;
  opacity: 0.3 !important;
}

.fc-day-today {
  color: var(--primary) !important;
  background-color: transparent !important;
}

.fc-timegrid-now-indicator-arrow {
  border-left-color: var(--info) !important;
  border-right-color: var(--info) !important;
}

.fc-timegrid-now-indicator-line {
  border-color: var(--info) !important;
}

.fc-list-empty {
  background-color: var(--grey6) !important;
}

.fc td {
  border: 1px solid var(--grey4) !important;
}

.fc-event:hover {
  cursor: pointer !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}

/* Buttons */

.fc-button-primary {
  color: var(--font) !important;
  background-color: var(--grey5) !important;
  border-color: var(--grey4) !important;
}

.fc-button-primary:hover {
  color: var(--font) !important;
  background-color: var(--grey4) !important;
  border-color: var(--grey4) !important;
}

.fc-button-primary:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.fc-button-primary:disabled {
  cursor: not-allowed !important;
  color: var(--font) !important;
  background-color: var(--grey5) !important;
  border-color: var(--grey4) !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  color: var(--font) !important;
  background-color: var(--grey4) !important;
  border-color: var(--grey4) !important;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
  color: var(--font) !important;
  background-color: var(--grey4) !important;
  border-color: var(--grey4) !important;
}

/* List View */

.fc-list-event-time,
.fc-list-event-graphic {
  display: none !important;
}

.fc-list-event:hover td {
  background-color: transparent !important;
}

.fc-list-sticky .fc-list-day > * {
  background-color: var(--grey6) !important;
}

.fc-list-day-cushion {
  background-color: transparent !important;
}

.fc-list {
  border: 1px solid var(--grey4) !important;
}

/* Workouts */

.workouts .fc-scrollgrid tbody .fc-scrollgrid-section ~ .fc-scrollgrid-section,
.workouts .fc-timegrid-axis,
.workouts .fc colgroup {
  /* display: none !important; */
}

.workouts .fc-scroller-harness,
.workouts .fc-scroller,
.workouts .fc-daygrid-body,
.workouts .fc-scrollgrid-sync-table {
  height: 100% !important;
}

@media (max-width: 48rem) {
  .fc-toolbar-title {
    font-size: 1.25rem !important;
    text-align: center;
  }
}

/* Time slots on workout day / week view */
.workout-calendar .fc tbody:last-child .fc-scrollgrid-section:not(:first-child) {
  display: none;
}

.workout-calendar .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  min-height: 75em;
  position: relative;
}
/* Time slots on workout day / week view */
